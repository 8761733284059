.switch-wrapper {
  margin: 1rem 0;

  @media screen and (max-width: $smartphone) {
    margin: 2rem 0;
  }
}

.switch-container {
  display: flex;
  justify-items: center;
  gap: 1rem;
}

.settings-switch-wrapper {
  position: relative;
  margin: 4rem 0;
}

.switch-text {
  display: block;
  cursor: pointer;
  font-size: 1.7rem;
}