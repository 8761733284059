.search {
    &-form {
        margin-bottom: 1.6rem;
        position: relative;

        &:before {
            content: "\f002";
            color: $gray-green;
            display: block;
            font-family: FontAwesome;
            font-size: 1.6rem;
            position: absolute;
            top: 50%;
            left: 1.6rem;
            transform: translateY(-50%);
        }

        input {
            border: none;
            box-shadow: 0 .4rem .8rem $gray3;
            border-radius: .4rem;
            padding-left: 4.8rem;
        }
    }

    &-result {
        &_one {
            background-color: $white;
            box-shadow: 0 .1rem .4rem $gray2;
            border-radius: .4rem;
            margin-bottom: 1.6rem;
            margin-top: 1.6rem;
            padding: 1.6rem 3.2rem 1.6rem 1.6rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-buy {
                font-size: 1.2rem;
                margin: 0 0 0 2.4rem;
                width: 15.5rem;
                height: 3.4rem;
                line-height: 3.4rem;
                padding: 0 .5rem;

                @media screen and (max-width: $smartphone) {
                    margin: 0;
                }
            }

            @media screen and (max-width: $tablet-small) {
                flex-direction: column;
                justify-content: flex-start;
                padding-right: 1.6rem;
            }
        }

        &_left {
            display: flex;

            @media screen and (max-width: $tablet-small) {
                margin-bottom: 3rem;
            }

            @media screen and (max-width: $smartphone) {
                display: block;
            }
        }

        &_right {
            display: flex;
            align-items: center;
        }

        &_pic {
            //background-color: $light-green;
            border-radius: .4rem;
            flex: 0 0 8rem;
            height: 8rem;
            width: 8rem;
            margin-right: 1.6rem;
            overflow: hidden;

            @media screen and (max-width: $smartphone) {
                display: block;
                margin: 1rem auto;
            }

            img {
                max-width: 100%;
                height: 8rem;
                width: 8rem;
                object-fit: contain;
            }
        }

        &_info {
            color: $black;
            font-size: 1.2rem;
            line-height: 2;
            padding: .2rem 0 0;
            flex: 1;

            h4 {
                color: $main-text;
                font-size: 1.8rem;
                font-weight: $font-bold;
                margin-bottom: .7rem;

                 @media screen and (max-width: $smartphone) {
                    text-align: center;
                 }
            }

            p {
                margin-bottom: 0
            }
        }

        &-epolicy {
            &_price {
                flex: 0.8 1;
                font-size: 2rem;
                white-space: nowrap;

                @media screen and (max-width: $smartphone) {
                    margin: 2rem 0;
                    font-size: 1.6rem;
                    text-align: center;
                }
            }
        }

        &-greencard {
            &_price {
                font-size: 2rem;
                white-space: nowrap;

                @media screen and (max-width: $smartphone) {
                    font-size: 1.6rem;
                    text-align: center;
                }
            }
        }
    }
}
