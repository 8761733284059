.suggestionsContainer {
    position: absolute;
    background-color: white;
    z-index: 1000;
    width: 100%;
    max-height: 20rem;
    overflow-y: auto;
}
.suggestionsContainerOpen {
    border: 0.2rem solid rgb(180, 201, 193);
}
.suggestionsList {
    padding: 0;
}
.suggestion {
    cursor: pointer;
    transition: background-color 200ms;
    padding: 1rem;
    font-size: 1.7rem;
    margin: 2rem 0;
}
.suggestion:hover {
    background-color: #1BA876;
}
.suggestion:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.1rem;
    background: #B4C9C1;
    left: 0;
    margin: 2rem 0;
}