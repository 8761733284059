.diia-pc-container {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  grid-gap: 3rem;
  margin-top: 2rem;
}

.diia-pc-container p {
  font-weight: bold;
}

.diia-mobile-container p {
  text-align: center;
  margin: 1rem 0;
  font-size: 1.7rem;
}

.diia-mobile-controls {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.diia-info {
  padding: 0 2rem;
  font-size: 1.7rem;
}

.diia-info li {
  list-style-type: disc;
  margin: 1rem 0;
}

.diia-msg {
  text-align: center;
  font-size: 1.7rem;
}

.diia-document-wrapper {
  margin-bottom: 3rem;
}

.diia-btn, .pess-btn, .bankid-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1.5rem;
  border: 0.2rem solid $black;
  border-radius: 5rem;
  max-width: 40rem;
  margin: 0 auto;
  cursor: pointer;
  font-size: 1.7rem;
}

.diia-btn img, .pess-btn img {
  width: 5rem;
  height: 5rem;
}

.diia-qr-info {
  width: 25rem;
  height: auto;
}

.diia-downloads-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5rem;
  margin-top: 2rem;
}

.diia-downloads-container img {
  width: 12rem;
  height: auto;
}

.diia-qrcode-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.diia-qrcode-container {
  padding: 5rem;
  background-color: $shadow-gray;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.9rem $granite-gray;
}

.diia-qrcode-container p {
  text-align: center;
  color: $white;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.diia-refresh-pc-deeplink {
  margin-top: 5rem;
}

.diia-refresh-mobile-deeplink {
  display: flex;
  margin: 1rem auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.diia-pc-timer {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 4rem;
  color: $white;
}

.diia-mobile-timer {
  text-align: center;
  margin: 1rem 0;
  font-size: 4rem;
  color: $black;
}

.diia-banner-wrapper {
  color: $white;
  background-color: $green;
  margin-bottom: 2rem;
  box-shadow: 0 .1rem .4rem $gray2;
  border-radius: 4px;
  padding: 3.7rem 3.2rem 3.2rem 3.2rem;
}

.diia-banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-size: 1.7rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.diia-banner-container img {
  width: 90px;
  height: 90px;
}