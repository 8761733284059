.black {
    color: $black;
}

.white {
    color: $white;
}

.green {
    color: $green;
}

.font-light {
    font-weight: $font-light;
}

.font-regular {
    font-weight: $font-regular;
}

.font-medium {
    font-weight: $font-medium;
}

.font-semibold {
    font-weight: $font-semibold;
}

.font-bold {
    font-weight: $font-bold;
}

.font-extrabold {
    font-weight: $font-extrabold;
}

.font-black {
    font-weight: $font-black;
}

.hide {
    display: none !important;
}
