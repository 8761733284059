.column {
    &-subtitle {
        font-size: 1.7rem;
        margin-bottom: 3.6rem;
    }

    &-left {
        &_inner {
            @media screen and (max-width: $tablet-small) {
                margin-bottom: 1rem;
            }
        }

        &_pic {
            padding-right: 0;
        }
    }

    &-right {
        display: flex;
        justify-content: flex-end;

        &:before {
            background-color: $light-green;
            content: '';
            display: block;
            width: .2rem;
            height: 100%;
            position: absolute;
            top: 0;
            left: -.1rem;
        }

        &_inner {
            max-width: none;
        }

        &-pic {
            padding-left: 0;
        }

        @media screen and (max-width: $tablet-small) {
            justify-content: flex-start;

            &:before {
                display: none;
            }
        }
    }

    &-pic {
        background-color: $gray-green;
        height: 100%;
    }

    &-text {
        font-size: 1.2rem;
        line-height: 2;
        max-width: 55rem;
        margin-bottom: 4.3rem;

        @media screen and (max-width: $tablet-small) {
            max-width: none;
        }
    }
}
