.bbcode-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.bbcode-color-select {
  width: 20rem;
  margin: 2rem 2rem 2rem 0;
}

.file-upload {
	border: 0.1rem solid transparent;
	padding: 1rem;
  color: $white;
	background-color: $green;
  text-transform: uppercase;
  box-shadow: 0 8px 16px rgba(54, 64, 60, 0.1607843137254902);
  margin: 2rem 2rem 2rem 0;
  font-size: 1.2rem;
  font-weight: bold;

  &:hover {
    color: $black;
    cursor: pointer;
    border: 0.1rem solid $green;
    background-color: transparent;
  }
}

.image-files-select {
	display: none;
}

.file-upload-disabled {
	background-color: $gray3;
	border: 0.1rem solid $gray-green;
	padding: 1rem;
	cursor: not-allowed;

  &:hover {
    color: $black;
	  background-color: $gray3;
  }
}

.image-notifications {
  display: block;
  width: 100%;
}

.image-preview-group {
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   width: 100%;
   flex-wrap: wrap;
   grid-gap: 2rem;
   gap: 2rem;
}

#bold-btn {
  font-weight: bolder;
};

#italic-btn {
  font-style: italic;
}

#underline-btn {
  text-decoration: underline;
}

#linethrough-btn {
  text-decoration: line-through;
}

.inline-tag {
  display: inline-flex;
}

.quote-tag {
  font-style: italic;
  text-align: center;
  word-wrap: break-word;
  background-color: $gainsboro;
  border-left: 0.5rem solid $green;
  margin: 2rem 0;
  padding: 2rem;
  line-height: 2.5rem;
}

.quote-tag:before {
	content: open-quote;
	font-size: 3rem;
}

.quote-tag:after {
	content: close-quote;
	font-size: 3rem;
}

.image-tag {
  width: 300px; 
  height: 300px; 
  border: 0.2rem solid $silver; 
  border-radius: 1rem;
}

.url-tag {
  color: $green;
  text-decoration: underline;

  &:hover {
    color: $dark-green;
    text-decoration: none;
  }
}

.code-tag {
  background-color: $white-smoke;
  border: 0.1rem solid $neon-silver;
  border-radius: 0.3rem;
  padding: 0.6rem;
  font-family: Courier, monospace;
  white-space: pre-wrap;
  overflow-x: auto;
}

.horizontal-line-tag {
  background-color: $black;
}

.list-tag {
  list-style: inside;
  margin-left: 2.5rem;
}

.spoiler-wrapper .spoiler-content {
  padding: 2rem;
  background-color: $gainsboro;
}

.youtube-container {
  width: 480px;
  height: 510px;

  @media screen and (max-width: $smartphone) {
    width: 220px;
    height: 260px;
  }

  @media screen and (max-width: $smartphone-285) {
    width: 210px;
    height: 280px;
  }
}