.btn {
    background: $white;
    border: .1rem solid $gray-green;
    border-radius: 0;
    color: $main-text;
    font-family: $font-family-base;
    font-size: 1.4rem;
    font-weight: $font-semibold;
    height: 4.5rem;
    margin-bottom: 1.9rem;
    width: 100%;
    padding: 0 .5rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    &:focus,
    &:focus-visible,
    &:active,
    &.focus {
        outline: none !important;
    }

    &:hover {
        background-color: $gray-green;
        color: $main-text;
    }

    &:active {
        background-color: $gray-green-dark;
    }

    &:disabled {
        opacity: .25;
    }

    .fa {
        margin-left: 2rem;
    }

    &-primary {
        background-color: $green;
        border: none;
        box-shadow: 0 8px 16px $gray;
        color: $white;

        a {
            color: $white;
        }

        &:hover,
        &:active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus{
            background-color: $green !important;
            border: none !important;
            color: $white !important;
        }

        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus{
            box-shadow: none !important;
        }
    }

    &-link, &-insurance-link {
        display: inline-block;
        line-height: 4.5rem;
        width: auto;
        padding: 0 4.3rem;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:focus {
            box-shadow: none;
        }

        @media screen and (max-width: $smartphone) {
            display: block;
            padding: 0 .5rem;
        }
    }

    &-white {
        box-shadow: 0 8px 16px $gray;
        color: $green;

        &-border {
            background: linear-gradient(to left, transparent 50%, $white 50%) right;
            background-size: 200%;
            transition: .5s ease-out;
            border-width: .2rem;
            border-color: $white;
            color: $white;
            margin-bottom: 0;

            &:hover,
            &:active,
            &.active {
                color: $green;
                background-position: left;
            }
        }
    }
}

.policy-btn {
    background: $white;
    border-radius: 0;
    color: $main-text;
    font-family: $font-family-base;
    font-size: 1.4rem;
    font-weight: $font-semibold;
    height: 4.5rem;
    margin-bottom: 1.9rem;
    width: 100%;
    padding: 0 .5rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    &:focus,
    &:focus-visible,
    &:active,
    &.focus {
        outline: none !important;
    }

    &:active {
        background-color: $gray-green-dark;
    }

    &:disabled {
        opacity: .25;
    }

    .fa {
        margin-left: 2rem;
    }

    &-primary {
        background-color: $green;
        border: none;
        box-shadow: 0 8px 16px $gray;
        color: $white;

        a {
            color: $white;
        }

        &:hover,
        &:active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
            background-color: $green !important;
            border: none !important;
            color: $white !important;
        }

        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
            box-shadow: none !important;
        }
    }

    &-link {
        display: inline-block;
        line-height: 4.5rem;
        width: auto;
        padding: 0 4.3rem;

        &:hover,
        &:focus{
            text-decoration: none;
        }

        &:focus {
            box-shadow: none;
        }

        @media screen and (max-width: $smartphone) {
            display: block;
            padding: 0 .5rem;
        }
    }

    &-white {
        box-shadow: 0 8px 16px $gray;
        color: $green;

        &-border {
            background: linear-gradient(to left, transparent 50%, $white 50%) right;
            background-size: 200%;
            transition: .5s ease-out;
            border-width: .2rem;
            border-color: $white;
            color: $white;
            margin-bottom: 0;

            &:hover,
            &:active,
            &.active {
                color: $green;
                background-position: left;
            }
        }
    }
}