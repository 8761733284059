.validate_field {
  border-color: $green !important;
  background: lighten($green, 55%);
}

.not_validate_field {
  border-color: $red !important;
  background: lighten($red, 55%);
}

.form-group {
  position: relative;
}

.form-group p {
  font-size: 1.7rem;
}

.franshise-title-wrapper {
  font-size: 1.7rem;
  cursor: default;

  @media screen and (max-width: $smartphone) {
    text-align: center;
  }
}

.franchise-control {
  width: 20rem;

  @media screen and (max-width: $smartphone) {
    margin: 0 auto;
  }
}

.form-group-error {
  label {
    color: $red;
  }
  input, textarea {
    border-color: $red !important;
    background: lighten($red, 55%);
  }
  p {
    color: $red;
    position: relative;
    animation-name: anim-error;
    animation-duration: 1000ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    margin: 0;
  }
  [class$='Control'] {
    border: 0.2rem solid $red;
  }
}

@keyframes anim-error {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.form-group-success {
  label {
    color: $green;
  }
  input, textarea {
    border-color: $green !important;
    background: lighten($green, 55%);
  }
  p {
    color: $green;
  }
}

.react-tel-input input[type='text'],
.react-tel-input input[type='tel'] {
  width: 100% !important;
  background-color: #fff !important;
  border: 0.2rem solid #b4c9c1 !important;
  border-radius: 0 !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none !important;
    border: none !important;
  }
}

.dropdown-menu {
  display: block;
}

.vehicle-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  div {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 0.5rem);
    font-size: 1.7rem;
  }
}

.carousel-item-osago {
  padding: 5px;
}

.rc-steps-item-title {
  font-size: 1.7rem;
}

.rc-steps-item-process .rc-steps-item-icon {
  background: $green !important;
}
.rc-steps-item-finish {
  .rc-steps-item-icon {
    border-color: $green !important;
  }

  .rc-steps-item-tail::after {
    background: $green !important;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
}

.period {
  &_calendar {
    width: 300px !important;
    z-index: 10 !important;

    .Calendar__monthSelectorItemText {
      display: flex;
      justify-content: center;
    }
    .Calendar__yearSelector {
      &::-webkit-scrollbar {
        display: block;
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
      }
      &::-webkit-scrollbar-thumb:active {
        background: rgba(0, 0, 0, 0.9);
      }
    }
  }
  &_date {
    text-decoration: underline !important;
  }
}
.calendarWrap {
  position: absolute;
  .btnClose {
    position: absolute;
    z-index: 11;
    right: 0.5rem;
    top: 0.5rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    cursor: pointer;
    justify-content: center;
  }
}
.owl-carousel .owl-stage-outer {
  overflow: initial;
}
.home-page {
  margin: 5rem 0;
}

.css-0 {
  display: flex;
  margin: auto;
}

.Overlay {
  z-index: 9999;
}

.header-logo {
  z-index: 20;
}

@media screen and (max-width: $tablet-big) {
  .header-logo {
    margin: 0 1rem;
  }
}

@media screen and (max-width: $smartphone) {
  .header-logo {
    margin: 0;
  }
}

.header-logo #logo {
  width: 350px;
  height: 145px;
  z-index: 20;
  transition: width 800ms;
}

@media screen and (max-width: $smartphone) {
  .header-logo #logo {
     width: 300px;
     height: 145px;
  }
}

@media screen and (max-width: $smartphone-small) {
  .header-logo #logo {
     width: 200px;
     height: 100px;
  }
}

.header.navbar-expand-xl.fixed-top {
  position: sticky;
  margin-top: 30px;
  &.scroll {
    margin-top: 0;
  }
}

.scroll .header-logo #logo {
  transition: width 800ms;
  width: 200px;
  height: 82px;
}

#socialLogo {
  display: none;
}

.restore-code_block {
  display: flex;
  max-width: 37rem;

  input {
    padding: 2rem 2.4rem;
    font-size: 1.5rem;

    @media screen and (max-width: ($smartphone + 10)) {
      width: 5rem;
      height: 4.2rem;
      padding: 2rem 0;
      font-size: 1.5rem;
      text-align: center;
    }
  }
}

.status-request {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11rem;
  flex-direction: row;
  
  p {
    margin-right: 2rem;
    font-size: 1.7rem;
    margin-bottom: 0;
  }
}

.status-completed {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11rem;
  flex-direction: column;
  
  p {
    margin-right: 2rem;
    font-size: 1.7rem;
    margin-bottom: 0;
  }
}

.policy-loading, .customer-data-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem auto;
}

.status-sended {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 5rem;
  width: 50%;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: $smartphone) {
    width: 100%;
  }
}

.send-newsletter-from {
  position: relative;
}

.manage-newsletter-receivers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem 0;

  @media screen and (max-width: $smartphone) {
    flex-direction: column;
  }
}

.agreement-wrapper {
  margin: 1rem 0;
}

.agreement-accepted {
  color: $black;
  font-weight: bold;
}

.agreement-declined {
  color: $red;
  font-weight: bold;
}

@media screen and (min-width: 1200px) {
  .header .container {
    max-width: 128rem;
    padding: 0;
  }
}
@media screen and (max-width: $hd) and (max-height: $hd-device-1600) {
  .header .container {
    max-width: 128rem;
    padding: 0 3.2rem;
  }
}
@media screen and (max-width: 575px) {
  .header-logo img {
    width: 250px;
    height: 94px;
    z-index: 20;
    transition: width 800ms;
  }
  .home-page:before {
    height: 60rem;
  }
}
.footer-menu {
  justify-content: space-between;
  gap: 2rem;
  padding: 0;

  @media screen and (max-width: $smartphone) {
    padding-left: 2rem;
  }
}

.footer-menu li {
  margin: 0;
}
.footer-menu li:first-child {
  width: auto;
}
a.disable-link {
  pointer-events: none;
  cursor: default;
  color: grey;
}
.choose_text {
  margin-left: 10rem;
}
.home-page-non-title {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 50rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  &-step {
    &:before {
      background: transparent radial-gradient(closest-side at 28% 32%, #1ba876 0%, #218b65 100%) 0% 0% no-repeat padding-box;
      content: '';
      display: block;
      width: 100%;
      height: 90rem;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.footer {
  position: relative;
  margin-top: auto;
}

.navbar-collapse {
  @media screen and (max-width: 1200px) {
    top: 9rem;
  }

  @media screen and (max-width: $hd) and (max-height: $hd-device-1600) {
    display: block;
  }
}
.main-wrapper {
  display: flex;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $smartphone) {
    display: block;
    margin: 2rem 0;
    padding: 0.8rem;
  }
}
.footer-content {
  padding-left: 1.2rem !important;
}

.home-page:before {
  height: 55rem !important;
}

.checkbox-car {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 575px) {
  .home-page:before {
    height: 60rem !important;
  }
}

.dateDropDown {
  padding: 0 0.5rem;
  text-align-last: center;

  &-container-modal {
    display: flex;
    justify-content: space-between;
    position: absolute;
    padding: 2rem;
    background-color: #fff;
    border: 0.2rem solid #B4C9C1;
    width: 100%;
    z-index: 2;
  }
  &-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &-option {
    text-align: start;
  }
  &-touch {
    padding: 0 0.5rem;
    text-align-last: center;
    border-color: #1BA876 !important;
    background: #e1faf1;
  }
  &-close{
    position: absolute;
    right: 0.6rem;
    top: 0px;
    cursor: pointer;
  }
}
.modal-insurance-save {
  margin-top: 1rem;
  margin-bottom: 0rem;
  width: 100%;
}

.number-form-visible, .calc-form-visible {
  display: block;
  margin-top: 2rem;
  padding: 0 3rem;
}

.number-form-hidden, .calc-form-hidden {
  display: none;
}

.vehicle-number {
  font-weight: bold;
}

.search-company {
  display: block;
}

.home-icon {
  margin-right: 0.5rem;
}

input::placeholder, textarea::placeholder { /* Firefox */
  color: $gray-green;
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $gray-green;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder { /* Microsoft Edge */
  color: $gray-green;
}

#policy-required-auto-number, #required-calc-form, #green-card-required-form {
  border: 0.2rem solid $red;
}

.error-msg-visible {
  color: $red;
  text-align: center;
  margin: 4rem 2rem;
  font-weight: bold;
  border: 0.2rem solid $red;
  padding: 2rem;
  font-size: 1.7rem;
}

.error-msg-visible button {
  margin: 2rem 2rem;
}

.error-privilege-visible {
  color: $red;
  margin: 2rem 0;
  font-size: 1.7rem;
}

.error-msg-hidden, .error-privilege-hidden {
  display: none;
}

.not-found-wrapper {
  display: flex;
  justify-content: center; 
  align-items: center;
}

.not-found-container {
  text-align: center;
  color: $black;
  background-color: $white;
  padding: 2rem 8rem;
  margin: 10rem 2rem;
  font-size: 1.7rem;
}

.not-found-container p {
  margin-bottom: 4rem;
}

.old-price {
  text-decoration: line-through;
  color: $red;
}

.policy-item-action-description, .policy-item-discount-description {
  white-space: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.promocode-container {
  margin-top: 1rem;
}

.cashback-item {
  text-align: center;
  background-color: $pastel-pea-green;
  padding: 0.5rem;
  margin: 2rem 0;
  border-radius: 4rem;
  cursor: default;
}

.price-item {
  text-align: center;
  background-color: $gold;
  padding: 0.5rem;
  margin: 2rem 0;
}

.notification-icon {
  margin-right: 1rem;
}

.arrow-forward-icon, .insurance-notification-icon {
  margin-left: 1rem;
  margin-right: 0;
}

.back-icon {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.document-type-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;

  @media screen and (max-width: $smartphone) {
    flex-direction: column;
  }
}

.customer-document-type-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (max-width: $smartphone) {
    flex-direction: column;
  }
}

.view-vehicle-info-btn, .view-customer-info-btn {
  border: 0.1rem solid transparent;
  background-color: $green;
  box-shadow: 0 8px 16px rgba(54,64,60,.160784);
  color: $white;
  cursor: pointer;

  &:hover {
    border: 0.1rem solid $green;
    background: transparent;
    color: $black;
  }
}

.date-label {
  white-space: normal;
}

.newline-date-label {
  white-space: pre-line;
}

.custom-input-label {
  display: block;
  white-space: normal;
}

.privilege-checkbox {
  margin: 3rem 0;
}

.customer-found-info {
  font-size: 1.7rem;
  text-align: center;
}

.greencard-cashback-info {
  font-size: 1.7rem;
  text-align: center;
  font-weight: bold;
}

.item-error {
  color: $red;
  font-size: 1.7rem;
  margin: 2rem 0;
}

.policy-error-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 1.5rem;
  background-color: $red;
  color: $white;
  width: 100%;
  margin: 4rem auto;
  position: relative;
  font-size: 1.7rem;
}

.customer-error-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 2rem;
  background-color: $red;
  color: $white;
  width: 100%;
  margin: 4rem auto;
  position: relative;
  font-size: 1.7rem;
}

.customer-warning-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 2rem;
  background-color: $gold;
  color: $black;
  width: 100%;
  margin: 4rem auto;
  position: relative;
  font-size: 1.7rem;
}

.customer-success-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 2rem;
  background-color: $green;
  color: $white;
  width: 100%;
  margin: 4rem auto;
  position: relative;
  font-size: 1.7rem;
}

.dev-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  background-color: $red;
  color: $white;
  width: 50%;
  margin: 4rem auto;
  position: relative;
  text-align: center;
  font-size: 1.7rem;

  @media screen and (max-width: $smartphone) {
    width: 100%;
  }
}

.dev-mode-title {
  font-weight: bold;
  color: $red;
  margin-left: 0.4rem;
  font-size: 1.7rem;
}

.dev-mode-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  background-color: $red;
  color: $white;
  width: 100%;
  margin: 4rem auto;
  position: relative;
  text-align: center;
  font-size: 1.7rem;
}

.dev-notification-icon, .dev-mode-notification-icon {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
}

.greencard-cashback-container {
  background-color: $gold;
  box-shadow: 0 .1rem .4rem $gray2;
  border-radius: .4rem;
  margin-bottom: 1.6rem;
  padding: 1.7rem 2rem 3.2rem;
  font-size: 1.7rem;

  @media screen and (max-width: $smartphone-middle) {
    padding: 1.7rem 1.5rem;
  }
}

.greencard-cashback-list {
  padding: 1rem;
  margin: 1rem;
}

.greencard-cashback-list li {
  list-style-type: disc;
  margin: 1rem 0;
}

.cashback-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  margin: 2rem 0;
}

.cashback-card-msg {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.card-wrapper {
  position: relative;
}

.card-icon {
  position: absolute;
  right: 2.5rem;
  top: 3.7rem;
  font-size: 3rem;
}

.auth-request, .email-send-request {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vehicle-reg-location {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;

  @media screen and (max-width: $smartphone) {
    display: block;
  }
}

.sign-in-title {
  font-size: 1.7rem;
  font-weight: bold;
}

.dialog-title {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin: 2rem;

  @media screen and (max-width: $smartphone) {
    font-size: 2rem;
  }
}

.dialog-title-error {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin: 2rem;
  color: $red;

  @media screen and (max-width: $smartphone) {
    font-size: 2rem;
  }
}

.dialog-success-icon {
  font-size: 4rem;
  color: $green;
}

.dialog-error-icon {
  font-size: 4rem;
  color: $red;
}

.dialog-content {
  text-align: center;
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.dialog-error-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1rem;
  background-color: $red;
  color: $white;
  width: 100%;
  margin: 2rem auto;
  position: relative;
  font-size: 1.7rem;
}

.dialog-warning-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1rem;
  background-color: $gold;
  color: $black;
  width: 100%;
  margin: 2rem auto;
  position: relative;
  font-size: 1.7rem;
}

.dialog-success-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1rem;
  background-color: $green;
  color: $white;
  width: 100%;
  margin: 2rem auto;
  position: relative;
  font-size: 1.7rem;
}

.select-error-label {
  color: $red;
}

.sign-in-wrapper {
  margin-top: 2rem;
}

.sign-in-container {
  position: relative;
}

.password-visibility-icon {
  position: absolute;
  right: 1rem;
  color: $dark-green;
  top: 4.5rem;
}

.dialog-controls {
  display: flex;
  align-items: center; 
  justify-content: flex-end;
  margin-top: 1rem
}

.dialog-controls button {
  margin-left: 2rem;
}

.manage-dialog-controls {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.login-error-notification {
  display: flex;
  justify-content: center;
  padding: 2rem 2rem;
  background-color: $red;
  color: $white;
  width: 100%;
  margin: 2rem auto;
  position: relative;
}

.insurance-error-container {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin: 4rem 0;
}

.insurance-error-container button {
  margin: 0 1rem;
}

.select-policy-document {
  margin: 2rem 0;
  padding: 0;
}

.policy-document-title {
  text-align: center;
  margin: 2rem 0;
}

.policy-controls {
  display: flex;
  justify-content: space-between;
}

.insurance-step-two {
  background-color: $gold;
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.insurance-step-four {
  background-color: $light-salmon-pink;
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.insurance-step-five {
  background-color: $pastel-pea-green;
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.link-component {
  text-decoration: underline;

  &:hover {
    color: $green;
    text-decoration: none;
  }
}

.analytics-map {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  .analytics-map-desktop-container {
      width: 800px;
      height: 600px;
      border: none;
  }

  .analytics-map-mobile-container {
      width: 400px;
      height: 400px;
      border: none;
  }
}

.mstbu-policy-validation {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;

  @media screen and (width: $ipad-pro-width) and (height: $ipad-pro-height) {
    display: contents;
  }

  @media screen and (max-width: $tablet-big) {
    flex-direction: column;
    align-items: normal;
    justify-items: normal;
  }

  @media screen and (max-width: $desktop) and (max-height: $nest-hub-height) {
    display: contents;
  }

  @media screen and (max-width: $smartphone) {
    display: contents;
  }
}