.nav {
    &-tabs {
        border-bottom: none;
        display: flex;
        font-size: 1.4rem;
        margin-left: 1.2rem;
        text-transform: uppercase;

        .nav {
            &-item {
                margin-bottom: 0;
                padding-left: 2rem;
            }

            &-link {
                border-color: $gray-green;
                border-radius: 3rem;
                color: $main-text;
                display: block;
                height: 100%;
                padding: 1.4rem 4rem 1.3rem;
                text-align: center;
                position: relative;

                &:hover {
                    color: $gray-green;
                }

                &.active {
                    background-color: #d9f2fc;
                    border-color: #c2eafa;
                    color: $main-text;
                }
            }
        }
    }
}

.tab {
    &-content {
        letter-spacing: .015rem;
        margin-bottom: 2.8rem;
        padding-top: 5.3rem;

        @media screen and (max-width: $tablet) {
            padding-top: 2.8rem;
        }

        @media screen and (max-width: $smartphone) {
            margin-bottom: 4rem;
        }
    }

    &-pane {
        h3 {
            font-size: 1.6rem;
            margin-bottom: 2.5rem;
        }
    }
}
