.owl {
    &-carousel {
        .owl-stage-outer {
            margin-bottom: 4.3rem;
        }

        .owl-dot {
            width: .9rem;
            height: .9rem;
            border-radius: 10px;
            background-color: $gray;
            margin: 0 .4rem;
            transition: all .2s linear;

            &.active {
                width: 1.8rem;
                background-color: $green;
            }
        }
    }

    &-dots {
        text-align: center;
    }

    &-thumbs {
        background-color: rgba($white, .5);
        border-radius: .5rem .5rem 0 0;
        padding: 2rem 1.5rem 0;
        display: flex;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 4;

        .owl-thumb-item {
            background-color: transparent;
            border-radius: .5rem .5rem 0 0;
            display: block;
            width: 13rem;
            height: 9rem;
            margin: 0 .5rem;
            overflow: hidden;
            position: relative;

            &:before {
                background-color: rgba($black, .5);
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: background-color .2s linear;
            }

            &.active,
            &:hover {
                &:before {
                    background-color: rgba($black, 0);
                }
            }
        }
    }
}
