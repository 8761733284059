.tabs-wrapper {
  display: block;
  margin: 0 auto;

  .tabs-container {
    display: flex;
    flex-direction: column;
    border: none;
    overflow: visible;
    justify-content: center;
    align-items: center;

    .tab-list.horizontal {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .tab-list.vertical {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .tab-list button {
      padding: 1.5rem;
      margin: 0;
      border: none;
      background-color: #f0f0f0;
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 1.7rem;

      &.active.horizontal {
        background-color: #e0e0e0;
        font-weight: bold;
        border-bottom: 0.5rem solid $gray-green-dark;
      }

      &.active.vertical {
        background-color: #e0e0e0;
        font-weight: bold;
        border-left: 0.5rem solid $gray-green-dark;
      }

      &.disabled.horizontal,
      &.disabled.vertical {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: #C2C8CC;
      }
    }

    .tab-panels-padding {
      padding: 1rem;
      width: 100%;

      .tab-panel-padding {
        display: none;
        padding: 1rem;
        border: none;
        background-color: transparent;

        &.active {
          display: block;
        }
      }
    }

    .tab-panels {
      width: 100%;

      .tab-panel {
        display: none;
        border: none;
        background-color: transparent;

        &.active {
          display: block;
        }
      }
    }
  }
}