.navbar {
    &-toggler {
        border: none;
        padding-left: 0;
        padding-right: 0;
        width: 3rem;

        i {
            background-color: $white;
            border-radius: 20%;
            display: block;
            height: .3rem;
            width: 100%;
            margin: .3rem 0;
        }
    }

    &-collapse {
        height: 100%;

        @media screen and (max-width: $hd) {
            background: $dark-green;
            overflow: hidden;
            position: fixed;
            top: 9rem;
            left: 0;
            width: 100%;
            height: auto;
            padding: 4rem 2rem;
        }

        nav {
            height: 100%;
        }
    }
}
