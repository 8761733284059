@font-face {
  font-family: "Roboto-Regular";
  src: url("../../fonts/Roboto-Regular.ttf") format("truetype");
  font-display: swap;
}

$font-family-base: Roboto-Regular, Helvetica Neue, Helvetica, Arial;

$font-size-base: 10px;

$font-light:     300;
$font-regular:   400;
$font-medium:    500;
$font-semibold:  600;
$font-bold:      700;
$font-extrabold: 800;
$font-black:     900;
