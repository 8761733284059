.breadcrumb {
    background: transparent;
    border-radius: 0;
    font-size: 1.2rem;
    margin-left: 10rem;
    padding: 2.5rem 1.5rem;

    @media screen and (max-width: $smartphone-small) {
        margin-left: 0;
    }

    &-item {
        a {
            color: $green;
        }

        & + .breadcrumb-item {
            padding-left: 1rem;

            &:before {
                content: '>';
                padding-right: 1rem;
            }
        }

        &:last-child {
            color: $green;
        }
    }
}
