* {
    margin: 0;
    padding: 0;
    outline: none !important;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    &:focus,
    &:focus-visible,
    &:active {
        outline: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
}

html {
    font-size: $font-size-base;
}

body {
    width: 100%;
    background: transparent radial-gradient(closest-side at 28% 32%, #1ba876 0%, #218b65 100%) 0% 0% no-repeat padding-box;
    color: $main-text;
    font-family: $font-family-base;
    font-weight: $font-regular;
    font-size: 1.6rem;
    line-height: 1.4;
    position: relative;
    transition: background-color 1s ease;
    margin: 0;
    padding: 0;

    @media screen and (max-width: $smartphone-middle) {
        font-size: 1.4rem;
    }
}

a {
    color: $main-text;
    transition: all .2s linear;

    &:hover {
        color: $main-text;
    }
}

img {
    display: block;
}

ul, li {
    list-style: none;
}

ul,ol {
    margin: 0;
}

section {
    position: relative;
    margin: 0;
    padding: 0;
}

figure {
    margin: 0;
}

address {
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0;
}

h1 {
    font-size: 4rem;
    font-weight: $font-bold;
    line-height:1.6;
    margin-bottom: 3.1rem;

    @media screen and (max-width: $smartphone-middle) {
        font-size: 3rem;
    }
}

h2 {
    font-size: 2.5rem;
    font-weight: $font-bold;

    @media screen and (max-width: $smartphone-middle) {
        font-size: 2.2rem;
    }
}

h3 {
    font-weight: $font-bold;
}

h4 {
    font-size: 2rem;
    font-weight: $font-bold;
    margin-bottom: 1.5rem;
}

@media screen and (min-width: $smartphone-middle) {
    .container {
        max-width: none;
    }
}

@media screen and (min-width: $hd) {
    .container {
        max-width: 128rem;
    }
}

@media screen and (max-width: $smartphone) {
    .section-main {
        margin-bottom: 4rem;
    }
}

@media screen and (max-width: $smartphone-middle) {
    .section-main {
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: $smartphone-small) {
    .section-main {
        margin-bottom: 1rem;
    }
}

.main-content-container {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 128rem;
   text-align: center;

   @media screen and (max-width: $smartphone) {
       display: block;
       width: 100%;
   }

   @media screen and (max-width: $tablet-big) {
        display: block;
        width: 100%;
        text-align: center;
   }
}

.main-info-container, .companies-container {
    position: relative;
    width: 50%;

    @media screen and (max-width: $smartphone) {
        width: 100%;
    }

    @media screen and (max-width: $tablet-big) {
        width: 100%;
    }
}

.navbar-expand-xl .navbar-toggler {
    display: none;

    @media screen and (max-width: $hd) and (max-height: $hd-device-1600) {
        display: block;
    }
}

.navbar-expand-xl .navbar-collapse {
    display: flex;
    flex-basis: auto;

    @media screen and (max-width: $hd) and (max-height: $hd-device-1600) {
        display: block !important;
    }
}

.info-container p {
    font-size: 2rem;
    font-style: italic;
    padding: 0;

    @media screen and (max-width: $smartphone) {
        padding: 1.5rem;
    }

    @media screen and (max-width: $tablet-big) {
        padding: 1.5rem;
    }
}

.main-info-container p {
    font-size: 2rem;
    font-weight: bold;
}

.mtsbu-icon {
    display: inline;
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
}

.main-block p {
    font-size: 2rem;
    font-weight: bold;
}