.review {
    &-one {
        background: $white;
        box-shadow: 0 1px 4px $gray2;
        border-radius: 4px;
        font-size: 1.2rem;
        margin-bottom: 1.6rem;
        padding: 3.2rem 3.2rem 2.6rem;

        &_name {
            font-size: 1.6rem;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.3rem;

            .date {
                color: $gray-green-dark;
                font-size: 1.4rem;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 2rem 1.5rem 1rem;

            &_name {
                flex-direction: column;
            }
        }
    }

    &-text {
        line-height: 2;
    }

    &-form {
        textarea {
            height: 12.5rem;
        }

        @media screen and (max-width: $tablet-small) {
            margin-top: 3rem;
        }
    }
}
