.spoiler-title {
  cursor: pointer;
}

.spoiler-title-centered {
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}

.spoiler-content {
  margin: 2.2rem;
}