.App {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    min-height: 100vh;
}

.logo-one {
    -webkit-align-items: center;
    align-items: center;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 1rem 6rem;
    padding: 2rem;

    @media screen and (max-width: $tablet) {
        padding: 4rem 2.5rem;
    }

    @media screen and (max-width: $smartphone-middle) {
        padding: 6rem 3rem;
    }

    @media screen and (max-width: $smartphone) {
        grid-template-columns: auto auto;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.click-notification {
    margin: 4rem 0;
    font-size: 4rem;
    text-align: center;
    color: $white;
    animation: notification 1s linear infinite;

    @keyframes notification {  
        50% { 
            opacity: 0; 
        }
    }
}
