$black:                 #000;
$white:                 #fff;
$main-text:             #36403C;
$gray:                  #36403C29;
$gray2:                 #36403C52;
$gray3:                 #36403C3D;
$light-green:           #EFF5F2;
$green:                 #1BA876;
$dark-green:            #218B65;
$gray-green:            #B4C9C1;
$gray-green-dark:       #8D9F97;
$red:                   #FF0000;
$gold:                  #FFD700;
$gainsboro:             #dcdcdc;
$morning-sky-blue:      #76998D;
$lion:                  #D6C5A5;
$sand-dune:             #99701E;
$extreme-violet:        #A500FF;
$orange:                #FFA500;
$pastel-pea-green:      #B7E1A3;
$silver:                #C0C0C0;
$neon-silver:           #CCCCCC;
$white-smoke:           #F4F4F4;
$granite-gray:          #666666;
$shadow-gray:           #464646;
$light-salmon-pink:     #FF9696;

$m-hd:                  1400px;
$hd:					1200px;
$desktop:				1024px;
$macbook:               1440px;
$laptop:                1366px;
$tablet-big:            1280px;
$device-height-1194:    1194px;
$device-1150:           1150px;
$tablet-1106:           1106px;
$tablet: 				991px;
$device-height-852:     852px;
$device-height-851:     851px;
$device-width-834:      834px;
$device-820:            820px;
$tablet-small:          767px;
$tablet-ipad-small:     768px;
$smartphone:			640px;
$smartphone-middle:		575px;
$device-540:            540px;
$smartphone-small:		480px;
$smartphone-435:        435px;
$smartphone-415:        415px;
$smartphone-413:        413px;
$smartphone-405:        405px;
$smartphone-395:        395px;
$device-width-393:      393px;
$smartphone-390:        390px;
$smartphone-380:        380px;
$smartphone-375:        375px;
$smartphone-365:        365px;
$smartphone-285:        285px;
$nest-hub-height:       600px;
$ipad-pro-width:        1024px;
$ipad-pro-height:       1366px;
$hd-device-1600:        1600px;
$hd-device-1700:        1700px;