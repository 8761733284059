.dashboard {
  display: flex;
  gap: 2rem;
  margin: 2rem 0;
  padding: 2rem 0;

  @media screen and (max-width: $smartphone) {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: center;
  }
}

.dashboard-items {
  list-style-type: none;
  padding: 0;
}

.dashboard-items li, .epolicy-franchise-items li {
  display: inline-block;
  margin-right: 1.5rem;

  @media screen and (max-width: $smartphone) {
    display: block;
    margin: 2rem 0;
  }
}

.dashboard-item {
  display: inline;
}

.dashboard-item a, .dashboard-link {
  padding: 2rem;
  border: 0.2rem solid $green;
  border-radius: 0.5rem;
  background: linear-gradient(to left, transparent 50%, $green 50%) right;
  background-size: 200%;
  transition: .5s ease-out;

  &:hover {
    color: $white;
    background-color: $green;
    background-position: left;
  }

  @media screen and (max-width: $smartphone) {
    display: block;
    text-align: center;
  }
}

.no-dashboard-item {
  color: $red;
  font-size: 1.7rem;
  margin: 1rem 2rem;
  display: inline-block;
}

.admin-balance {
  display: flex;
  gap: 2rem;

  @media screen and (max-width: $smartphone) {
    padding: 0;
  }
}

.admin-item-title {
  font-weight: bold;
}

.item-title {
  font-size: 1.7rem;
  font-weight: normal;
  margin: 1rem 0;
}

.admin-error-notification {
  display: block;
  text-align: center;
  word-wrap: break-word;
  padding: 2.5rem 2rem;
  background-color: $red;
  color: $white;
  width: 50%;
  margin: 4rem auto;
  position: relative;
  font-size: 1.7rem;

  @media screen and (max-width: $smartphone) {
    width: 100%;
  }
}

.admin-success-notification {
  display: block;
  text-align: center;
  word-wrap: break-word;
  padding: 2.5rem 2rem;
  background-color: $green;
  color: $white;
  width: 50%;
  margin: 4rem auto;
  position: relative;
  font-size: 1.7rem;

  @media screen and (max-width: $smartphone) {
    width: 100%;
  }
}

.manage-item-wrapper {
  margin: 4rem 0;

  @media screen and (max-width: $smartphone) {
    margin: 0;
  }
}

.manage-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-item, .view-item, .delete-item, .hide-item, .send-item, .add-item {
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: linear-gradient(to left, transparent 50%, $green 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
  margin: 1rem;
  cursor: pointer;

  &:hover {
    color: $white;
    background-color: $green;
    background-position: left;
  }

  @media screen and (max-width: $smartphone) {
    display: inline-block;
    text-align: center;
  }
}

.dashboard-container {
  margin: 3rem 0;

  @media screen and (max-width: $smartphone) {
    margin-top: 4rem;
  }
}

.dashboard-container button {
  margin: 2rem 2rem 2rem 0;
}

.add-dashboard-item-form, .edit-dashboard-item-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.submit-btn, .edit-btn, .reset-btn, .city-btn, .admin-btn, .insurance-btn, .dialog-btn, .bbcode-btn {
  border: 0.1rem solid transparent;
  background-color: $green;
  box-shadow: 0 8px 16px rgba(54, 64, 60, 0.1607843137254902);
  color: $white;
  cursor: pointer;
  padding: 1rem;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    border: 0.1rem solid $green;
    background-color: transparent;
    color: $black;
  }
}

.insurance-btn:disabled {
  cursor: not-allowed;
  border: 0.1rem solid $gray3;
  background-color: $gray3;
  color: $black;
}

.close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}

.dialog-close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  color: $red;
}

.cities-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0;
  padding: 0;
  grid-gap: 0.9rem;
  gap: 0.9rem;

  @media screen and (width: $device-width-834) and (max-height: $device-height-1194) and (orientation: portrait) {
    justify-content: flex-start;
    grid-gap: 2rem;
    gap: 2rem;
  }

  @media screen and (width: $device-820) and (height: $tablet-1106) and (orientation: portrait) {
    justify-content: flex-start;
    grid-gap: 1.8rem;
    gap: 1.8rem;
  }

  @media screen and (width: $tablet-ipad-small) and (height: $desktop) and (orientation: portrait) {
    justify-content: flex-start;
    grid-gap: 1.6rem;
    gap: 1.6rem;
  }

  @media screen and (max-width: $smartphone-435) {
    justify-content: flex-start;
    grid-gap: 3.1rem;
    gap: 3.1rem;
  }

  @media screen and (max-width: $smartphone-415) {
    justify-content: flex-start;
    grid-gap: 2.6rem;
    gap: 2.6rem;
  }

  @media screen and (max-width: $smartphone-413) {
    justify-content: flex-start;
    grid-gap: 3.2rem;
    gap: 3.2rem;
  }

  @media screen and (max-width: $smartphone-395) {
    justify-content: flex-start;
    grid-gap: 2.7rem;
    gap: 2.7rem;
  }

  @media screen and (width: $device-width-393) and (-webkit-device-pixel-ratio: 3) and (min-resolution: 3dppx) {
    justify-content: flex-start;
    grid-gap: 1.9rem;
    gap: 1.9rem;
  }

  @media screen and (max-width: $smartphone-390) {
    justify-content: flex-start;
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  @media screen and (max-width: $smartphone-380) {
    justify-content: flex-start;
    grid-gap: 2.1rem;
    gap: 2.1rem;
  }

  @media screen and (max-width: $smartphone-375) {
    justify-content: flex-start;
    grid-gap: 1.3rem;
    gap: 1.3rem;
  }

  @media screen and (max-width: $smartphone-365) {
    justify-content: flex-start;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

.new-promocode, .vin-code-available, .cashback-enabled, .company-hidden, .is-state-reg, .is-analytics-data-available {
  color: $dark-green;
}

.used-promocode, .dont-have-vin-code, .cashback-disabled, .company-visible, .is-foreign-reg, .is-analytics-data-unavailable {
  color: $red;
}

.itemsCounter {
  margin-bottom: 4rem;
}

.vehicle-cat-details, .vehicle-kind-details {
  padding: 2rem;
  background-color: $gainsboro;
}

.dashboard-cashback-form {
  margin-top: 2rem;
}

.percentage-container {
  position: relative;

  #percentage-field {
    width: auto;
  }

  .percentage-icon {
    position: absolute;
    left: 19rem;
    top: 6rem;
    font-size: 2.2rem;
  }
}

.account-logging-in {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 20rem;
}

.customers-wrapper, .reports-wrapper, .analytics-wrapper {
  width: 100%;
  overflow-x: auto;
}

.customers, .reports, .analytics {
  width: 100%;

  thead {
    th  {
      background-color: #ccc;
      color: #222;
      padding: 2rem;
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: #eee;
      }
    }
  }

  tbody {
    td {
      padding: 1rem;
    }
  }
}

.settings-wrapper {
  margin: 10rem 0;
  display: block;
}

.manage-setting-item-container {
  position: absolute;
  left: 12rem;
  bottom: 6rem;
}

.company-info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  .company-title {
    text-align: center;
  }

  .company-logo {
    margin: 2rem 0;
  }

  .company-logo img {
    max-width: 100%;
    height: 20rem;
    width: 20rem;
    object-fit: contain;
  }

  .epolicy-franchises-wrapper {
    margin: 4rem 0;
  }

  .epolicy-franchises-title {
    text-align: center;
    font-weight: bold;
    font-size: 1.7rem;
  }

  .epolicy-franchise-items {
    list-style-type: none;
    padding: 0;
    text-align: center;
  }
}

.manage-company {
  display: block;
  text-align: center;
}

.policy-draft-status, .policy-expired-status {
  color: $red;
  font-weight: bold;
}

.policy-request-status {
  color: $morning-sky-blue;
  font-weight: bold;
}

.policy-sending-otp-status {
  color: $lion;
  font-weight: bold;
}

.policy-sended-otp-status {
  color: $sand-dune;
  font-weight: bold;
}

.policy-pay-offer-status {
  color: $extreme-violet;
  font-weight: bold;
}

.policy-paid-offer-status {
  color: $orange;
  font-weight: bold;
}

.policy-emitted-status {
  color: $green;
  font-weight: bold;
}

.search-items-wrapper {
  position: relative;
}

.clear-search-icon {
  position: absolute;
  right: 1rem;
  top: 1.2rem;
  font-size: 2rem;
  cursor: pointer;
  color: $black;

  &:hover {
    color: $red;
  }
}

.search-items-found-count {
  margin: 2rem 0;
}

.customer-documents-wrapper {
  .customer-documents-title {
    margin: 2rem 0;
  }
}