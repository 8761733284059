.select2 {
    &-container {
        &--default {
            .select2-selection {
                &--single {
                    background-color: $white;
                    border: .2rem solid $gray-green;
                    border-radius: 0;
                    font-size: 1.2rem;
                    height: 4.5rem;

                    .select2-selection {
                        &__rendered {
                            line-height: 4.1rem;
                        }

                        &__arrow {
                            height: 4.1rem;
                            width: 3.6rem;
                            top: .2rem;
                            right: .2rem;

                            &:before {
                                content: '\f0d7';
                                color: $main-text;
                                display: inline-block;
                                font-family: FontAwesome;
                                font-size: 1.6rem;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }

                            b {
                                display: none;
                            }
                        }

                        &__clear {
                            display: none;
                        }
                    }
                }
            }

            .select2-results {
                &__option {
                    color: $main-text;
                    padding: 1rem .5rem .8rem 1.4rem;
                    position: relative;

                    &[aria-selected=true] {
                        background-color: $gray;
                    }

                    &--highlighted[aria-selected] {
                        background-color: $gray2;
                    }
                }
            }
        }

        .select2-selection {
            &--single {
                .select2-selection__rendered {
                    padding-left: 1.4rem;
                    padding-right: 3.6rem;
                    text-align: left;
                }
            }
        }

        &--open  {
            .select2-dropdown {
                border: .2rem solid $gray-green;
                border-top: none;
                font-size: 1.2rem;

                &--below {
                    border-radius: 0;
                }
            }
        }
    }

    &-dropdown {
        border-top-style: dashed;
        box-shadow: 0 .2rem .2rem .2rem rgba(0, 0, 0, 0.07);
        font-size: 1.6rem;
        margin-top: -.1rem;
    }

    &-search--dropdown {
        display: none;
    }
}

.select2-container--open {
    .simple-select_dropdown {
        border-color: $gray-green;

        &.select2-dropdown--below {
            border-top-color: $gray-green;
        }
    }
}
