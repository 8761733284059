.analytics-wrapper {
    display: block;
    width: 100%;

    .analytics-select {
        position: relative;
        margin: 2rem 0;
        width: 10rem;
    }

    .analytics-chart {
        width: 100%;
        height: auto;

        @media screen and (max-width: $smartphone) {
            height: 30rem;
        }
    }
}