.pagination-wrapper {
    display: inline-block;
    margin-top: 6rem;
    position: relative;
}

.pagination-container {
    list-style: none;
    border-radius: 0.25rem;
    padding: 0;
}

.pagination-page-item {
    display: inline-block;
    margin-bottom: 4rem;
}

.pagination-page-item.active .pagination-page-link {
    z-index: 3;
    color: $white;
    background-color: $green;
    border-color: $green;
    cursor: not-allowed;
}

.pagination-page-link {
    position: relative;
    display: block;
    padding: 1rem;
    line-height: 1.25;
    color: $black;
    background-color: $white;
    border: 1px solid $gainsboro;

    &:hover {
        z-index: 2;
        color: $black;
        text-decoration: none;
        background-color: $gainsboro;
        border-color: $gainsboro;
    }
}