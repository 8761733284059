/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
// Font Awesome core compile (Web Fonts-based)
// -------------------------

@import "sass/functions";
@import "sass/variables";
@import "sass/mixins";
@import "sass/core";
@import "sass/sizing";
@import "sass/fixed-width";
@import "sass/list";
@import "sass/bordered-pulled";
@import "sass/animated";
@import "sass/rotated-flipped";
@import "sass/stacked";
@import "sass/icons";
@import "sass/screen-reader";
