.registration {
    &-block {
        width: 100%;
        max-width: 560px;
        height: 448px;
        background-color: $white;
        box-shadow: 0 .8rem 1.6rem $gray;
        border-radius: .4rem;
        margin: 0 8.1rem 5rem 0;
        padding: 4.9rem 6.4rem;

        h2 {
            font-size: 2.1rem;
            margin: 0 0 1.1rem;
        }

        .subtitle {
            font-size: 1.2rem;
            margin-bottom: 3.5rem;
        }

        @media screen and (max-width: $desktop) {
            padding: 4rem;
            height: auto;
        }

        @media screen and (max-width: $tablet-small) {
            max-width: none;
            margin-right: 0;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 3rem 2rem;
        }
    }
}
