.accordion-block {
    margin-bottom: 3rem;

    .card {
        border: none;
        margin-bottom: 2rem;

        &-header {
            background-color: $white;
            border: .1rem solid $gray-green;
            border-radius: .5rem;
            cursor: pointer;
            font-size: 1.8rem;
            padding: 0;
            position: relative;

            .fa {
                font-size: 2.6rem;
                position: absolute;
                top: 50%;
                right: 1.5rem;
                transform: translateY(-50%);
            }
        }

        &-link {
            display: block;
            padding: 1.7rem 2.5rem 1.6rem;

            .fa {
                &-angle-down {
                    display: none;
                }
            }

            &.collapsed {
                .fa {
                    &-angle-down {
                        display: block;
                    }

                    &-angle-up {
                        display: none;
                    }
                }
            }
        }

        &-body {
            font-size: 1.8rem;
            padding: 4rem 0 2rem;

            h4 {
                font-size: 2.4rem;
                font-weight: $font-bold;
                margin: 4rem 0 .5rem;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        ol {
            margin: 1.5rem 0;
            padding-left: 2.5rem;
            list-style: none;
            counter-reset: li;

            li {
                counter-increment: li;
            }

            li:before {
                content: counter(li)".";
                display: inline-block;
                width: 2rem;
                margin-left: -2rem;

            }
        }

        ul {
            margin: 1.5rem 0;
            padding-left: 2.5rem;

            li:before {
                content: "•";
                display: inline-block;
                width: 2rem;
                margin-left: -2rem;
            }
        }
    }
}
