.modal {
    z-index: 1100;

    &-backdrop {
        background-color: $white;
        opacity: .9;
        z-index: 1099;

        &.show {
            opacity: .9;
        }
    }

    &-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        margin: 0;

        @media screen and (max-width: $smartphone-small) {
            top: 0;
            left: 0;
            transform: none !important;
        }

        .close {
            cursor: pointer;
            font-size: 2.8rem;
            position: absolute;
            top: -2.3rem;
            right: -1.4rem;
            opacity: 1;
            z-index: 10;

            @media screen and (max-width: $smartphone-small) {
                top: 3rem;
                right: 2rem;
            }
        }
    }

    &-content {
        border-color: $gray-green;
        border-radius: .5rem;
        box-shadow: 0 .2rem .6rem rgba($black, .14);
        padding: 4.9rem 2rem;

        @media screen and (max-width: $smartphone-small) {
            border: none;
            border-radius: 0;
            box-shadow: none;
        }
    }

    &-video {
        width: 100%;
        max-width: 140rem;

        @media screen and (max-width: ($hd + 240)) {
            max-width: 100rem;
            padding: 15px;

            .close {
                top: -1rem;
                right: 0;
            }
        }

        .modal-content {
            border: none;
            border-radius: 1rem;
            box-shadow: 0 0 1rem rgba($black, .25);
            padding: 0;
            overflow: hidden;
        }

        iframe {
            width: 100%;
        }
    }

    form {
        max-width: 30rem;
        margin: 0 auto;
        width: 100%;
    }

    .btn {
        height: 6rem;
        line-height: 5.8rem;
        width: 100%;
    }
}
