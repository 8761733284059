.header {
    background-color: transparent;
    color: $white;
    height: 9rem;
    transition: all .2s linear;
    z-index: 1090;

    &.scroll {
        background-color: $green;
        box-shadow: 0 8px 16px $gray;

        .header-menu li a {
            &:hover,
            &.active {
                background-color: $dark-green;
            }
        }

        .dropdown.show {
            background-color: $dark-green;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-width: 144rem;
        padding: 0 3.2rem;
        position: relative;

        @media screen and (max-width: ($m-hd - 122)) {
            padding: 0 1.5rem;
        }
    }

    a {
        color: $white;
    }


    &-logo {
        flex: 0 0 20rem;
        font-weight: $font-semibold;

        img {
            width: 100%;
            transition: all .2s linear;
        }

        a {
            display: block;
        }

        @media screen and (max-width: $smartphone-middle) {
            flex: 0 0 10rem;
        }
    }

    &-right {
        display: flex;
        align-items: center;
        height: 100%;
        text-align: right;
    }

    &-menu {
        display: flex;
        align-items: center;
        height: 100%;

        @media screen and (max-width: $hd) {
            flex-direction: column;
            align-items: flex-end;
        }

        li {
            height: 100%;

            a {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 2rem;

                &:hover,
                &.active {
                    background-color: $green;
                }

                @media screen and (max-width: ($m-hd - 100)) {
                    padding: 0 1.5rem;
                }

                @media screen and (max-width: $hd) {
                    padding: 0 0 .7rem;
                }
            }
        }

        a {
            color: $white;
        }
    }

    .btn-login {
        font-size: 1.2rem;
        height: 3.4rem;
        line-height: 3rem;
        margin: 0 0 0 2.4rem;
        padding: 0 3rem;

        @media screen and (max-width: ($m-hd - 100)) {
            margin-left: 3rem;
        }
    }

    .dropdown {
        display: flex;
        align-items: center;
        height: 100%;

        &.show {
            background-color: $green;
        }

        &-menu {
            background-color: $green;
            border: none;
            border-radius: 0;
            box-shadow: 0 8px 16px $gray;
            color: $white;
            font-size: 1.4rem;
            margin-top: -.1rem;
            @media screen and (max-width: $hd) {
              right: 0;
              left: auto;
            }

            a {
                color: $white;
            }
        }

        &-item {
            color: $white;
            padding: 1rem 1.5rem;

            &:hover,
            &:focus {
                background-color: $dark-green;
                color: $white;
            }

            &.active,
            &:active {
                background-color: $dark-green;
                color: $gray-green-dark;
            }

            &.disabled,
            &:disabled {
                opacity: .5;
            }
        }
    }
}
