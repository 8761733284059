.image-zoom-component {
    position: relative; 
    width: 100%; 
    height: 100%;
    margin: 2rem 0;
}

.image-zoom-item {
    width: 200px;
    height: 200px;
    object-fit: fill;
    cursor: zoom-in;
    border: 0.2rem solid $silver;

    &:hover {
        border-color: $green;
    }
}

.image-zoomed-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: zoom-out;
}

.image-zoomed-container {
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    border: 0.2rem solid $green;
    opacity: 1;
}

.image-zoomed-item {
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: $white;
}

.image-zoomed-caption {
    color: $black;
    background-color: $white;
    margin: 0;
    padding: 1rem;
    text-align: center;
    word-wrap: break-word;
    font-size: 1.7rem;
}