.section {
    &-main {
        color: $white;

        .main-container {
            position: relative;
            width: 50%;
            padding-right: 2rem;

            @media screen and (max-width: $desktop) {
                padding: 0 1.5rem;
            }

            @media screen and (max-width: $tablet-big) {
                padding: 0 1.5rem;
            }

            @media screen and (min-width: $tablet-ipad-small) and (min-height: $desktop) and (orientation: portrait) { 
                padding: 0 1.5rem; // Adds padding on iPad on portrait mode
            }

            @media screen and (max-width: $tablet) {
                width: 100%;
                padding: 4rem 2rem;
            }

            @media screen and (max-width: $smartphone) {
                width: 100%;
            }
        }

        .main-block {
            font-size: 1.9rem;
            line-height: 1.85;
            max-width: 60rem;
            margin-bottom: 3rem;

            @media screen and (max-width: $smartphone) {
                font-size: 1.7rem;
                line-height: 1.5;
            }

            @media screen and (max-width: $tablet-big) {
                max-width: 100%;
                text-align: center;
            }
        }

        .info-container {
            width: 50%;
            position: relative;

            @media screen and (max-width: $tablet) {
                width: 100%;
            }

            @media screen and (max-width: $tablet-big) {
                padding: 0 1.5rem;
            }

            @media screen and (max-width: $smartphone) {
                width: 100%;
            }
        }

        .info-container > h1 {
            @media screen and (max-width: $smartphone) {
                text-align: center;
            }

            @media screen and (max-width: $tablet-big) {
                text-align: center;
            }
        }

        .insurance-wrapper {
            display: table-row;

            @media screen and (max-width: $tablet) {
                display: block;
            }

            @media screen and (max-width: $desktop) and (max-height: $nest-hub-height) {
                display: block;
            }

            @media screen and (max-width: $ipad-pro-width) and (max-height: $ipad-pro-height) {
                display: block;
            }

            @media screen and (max-width: $hd) and (max-height: $hd-device-1600) {
                display: block;
            }

            @media screen and (max-width: $smartphone) {
                display: block;
            }

            @media screen and (max-width: $tablet-big) {
                display: block;
                margin-bottom: 3rem;
            }
        }

         .insurance-container {
            display: block;

            @media screen and (max-width: $desktop) and (max-height: $nest-hub-height) {
                display: grid;
            }

            @media screen and (max-width: $ipad-pro-width) and (max-height: $ipad-pro-height) {
                display: grid;
            }

            @media screen and (max-width: $hd) and (max-height: $hd-device-1600) {
                display: grid;
            }

            @media screen and (max-width: $smartphone) {
                display: block;
                width: 100%;
            }
        }

        .insurance-nav {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            justify-items: center;
            gap: 2rem;

            @media screen and (width: $ipad-pro-width) and (height: $ipad-pro-height) {
                flex-direction: column;
                align-items: normal;
                justify-items: normal;
            }

            @media screen and (max-width: $tablet-big) {
                flex-direction: column;
                align-items: normal;
                justify-items: normal;
            }

            @media screen and (max-width: $desktop) and (max-height: $nest-hub-height) {
                flex-direction: column;
                align-items: normal;
                justify-items: normal;
            }

            @media screen and (max-width: $smartphone) {
                flex-direction: column;
                justify-items: normal;
                align-items: normal;
            }
        }
    }

    &-white {
        background-color: $white;
        border-radius: .4rem;
        margin: 2rem 0;
        padding: 2rem 3rem;
        position: relative;

        @media screen and (max-width: $desktop) {
            padding: 4rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 1rem;
        }

        h1 {
            font-size: 2.6rem;
            margin-bottom: 2.6rem;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 2.2rem;
            }
        }
    }

    &-osago {
        .subtitle {
            font-size: 1.8rem;
            margin-bottom: 1.5rem;
            color: $black;
            font-weight: bold;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 1.6rem;
                margin-bottom: 0;
            }

            .subtitle-green {
              color: $green;
            }
        }

        .steps {
            color: $green;
            font-size: 1.8rem;
            display: flex;
            justify-content: space-between;
            margin: 0 0 7rem;
            position: relative;

            &:before {
                background-color: $green;
                content: '';
                display: block;
                height: .2rem;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            li {
                background-color: $white;
                padding: 0 5rem;
                z-index: 2;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                @media screen and (max-width: $desktop) {
                    padding: 0 2.5rem;
                }
            }

            @media screen and (max-width: $tablet-small) {
                flex-direction: column;

                &:before {
                    display: none;
                }

                li {
                    padding: 0;
                }
            }

            @media screen and (max-width: $smartphone-middle) {
                font-size: 1.6rem;
                margin: 0 0 3rem;
            }
        }
    }

    &-simple {
        margin-bottom: 12.5rem;

        h2 {
            font-size: 4rem;
            margin: 1.2rem 0 3.6rem;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 3rem;
            }
        }

        p {
            margin-bottom: 0;
        }

        &_right {
            margin-bottom: 15.7rem;

            .column-right {
                &_inner {
                    max-width: 56rem;

                    @media screen and (max-width: $tablet-small) {
                        max-width: none;
                    }
                }
            }
        }

        @media screen and (max-width: $tablet-small) {
            margin-bottom: 9rem;

            &_right {
                margin-bottom: 9rem;
            }

            .column-left_inner {
                margin-bottom: 0;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 6rem;
        }
    }

    &-reviews {
        margin-bottom: 5.8rem;
    }

    &-contacts {
        margin-bottom: 6.4rem;
        padding: 7.7rem 6.4rem 4.5rem;

        h2 {
            margin-bottom: 3rem
        }

        .subtitle-green {
            color: $green;
            margin-bottom: .8rem
        }

        .contact {
            &-address {
                line-height: 1.8;
                margin-bottom: 3rem;
            }

            &-phones {
                font-size: 1.8rem;
                margin-bottom: 2.7rem;

                li {
                    padding: .2rem 0;
                }
            }

            &-form {
                padding-top: .9rem;

                textarea {
                    height: 9.3rem;
                }
            }
        }

        @media screen and (max-width: $desktop) {
            padding: 4rem;
        }

        @media screen and (max-width: $tablet-small) {
            &_info {
                margin-bottom: 3rem;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 3rem 2rem;
        }
    }

    &-registration {
        min-height: 75.6rem;

        .column-right {
            &:before {
                display: none;
            }
        }

        @media screen and (max-width: $tablet-small) {
            min-height: 50rem;
        }
    }
}
