.pay {
    &-choose {
        padding-top: 3rem;
        h4 {
            margin-bottom: 2.8rem;
        }

        .btn-login {
            border: .2rem solid $green;
            color: $green;
            height: 3.4rem;
            line-height: 3rem;
            margin-bottom: 2.8rem;
            padding: 0 2.6rem;
        }

        &_info {
            background-color: $white;
            box-shadow: 0 .1rem .4rem $gray2;
            border-radius: 4px;
            padding: 3.7rem 3.2rem 3.2rem 3.2rem;

            h4 {
                margin-bottom: 2.8rem;
            }

            .subtitle {
                font-size: 1.6rem;
                margin-bottom: 2rem;
            }

            &-list {
                font-size: 1.7rem;
                margin: 0 0 1.2rem;
                display: flex;
                flex-flow: column wrap;
                justify-content: space-between;
                padding: 0;

                li {
                    margin-bottom: 2rem;

                    p {
                        margin-bottom: 1.1rem;
                    }
                }

                @media screen and (min-width: 562px) {
                    flex-flow: row wrap;
                }

                @media screen and (min-width: 992px) {
                    flex-flow: column wrap;
                }

            }

            .btn-edit {
                margin: 0;
                width: 100%;
                height: 3.4rem;
                line-height: 3.4rem;
            }

            &-block {
                border-top: .2rem solid $light-green;
                margin-top: 1.6rem;
                padding-top: 2.2rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                padding: 2.5rem 1.5rem;
            }
        }
    }

    &-policy {
        &_form {
            background-color: $white;
            box-shadow: 0 .1rem .4rem $gray2;
            border-radius: .4rem;
            margin-bottom: 1.6rem;
            padding: 1.7rem 2rem 3.2rem;


            @media screen and (max-width: $smartphone-middle) {
              padding: 1.7rem 1.5rem;
            }
        }

        &_title {
            font-weight: $font-bold;
            font-size: 1.7rem;
            margin: 2rem 0;
        }

        .form-check {
            margin-bottom: 0;
            padding-top: 2.4rem;
        }
    }
}

.wfp-checkout, .portmone-checkout {
    .wfp-page-container, .portmone-page-container {
        width: 100%;
        height: 100vh;
        border: none;
    }
}