input,
button,
textarea,
select {
    background-color: $white;
    border: .2rem solid $gray-green;
    border-radius: 0;
    font-family: $font-family-base;
    font-size: 1.2rem;
    appearance: none;
    -webkit-appearance: none;

    &::-webkit-input-placeholder {
        color: $gray-green;
    }
    &::-moz-placeholder {
        color: $gray-green;
    }
    &:-moz-placeholder {
        color: $gray-green;
    }
    &:-ms-input-placeholder {
        color: $gray-green;
    }

    &:focus,
    &:active {
        border-color: $green;
    }
}

input,
select {
    padding: 0 0.9rem;
    width: 100%;
    height: 4.5rem;
    font-size: 1.7rem;
}

textarea {
    display: block;
    width: 100%;
    padding: 1.4rem;
    resize: none;
    font-size: 1.7rem;
}

label {
    color: $black;
    font-size: 1.7rem;
    font-weight: $font-medium;
    margin-bottom: .5rem;
}

.form {
    &-group {
        margin: 2rem 0;
        position: relative;
    }

    &-check {
        margin-bottom: 1.1rem;
        margin-right: 2rem;
        padding: 0;

        label {
            cursor: pointer;
            line-height: 2rem;
            margin: 0;
            padding-left: 3.2rem;
            position: relative;

            @media screen and (max-width: $smartphone) {
                padding-left: 2.5rem;
            }

            &:before {
                background-color: $white;
                border: .2rem solid $gray-green;
                content: "";
                display: block;
                width: 2rem;
                height: 2rem;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        input[type="checkbox"]:checked + label {
            color: $main-text;

            &:before {
                border-color: $green;
            }

            &:after {
                background-color: $green;
                content: '';
                display: block;
                width: .8rem;
                height: .8rem;
                position: absolute;
                top: .6rem;
                left: .6rem;
            }
        }
    }

    &-radio {
        label {
            cursor: pointer;
            font-size: 1.7rem;
            height: 2rem;
            line-height: 2rem;
            padding-left: 3.3rem;
            position: relative;

            &:before {
                background-color: $white;
                border: .2rem solid $gray-green;
                border-radius: 100%;
                content: '';
                display: block;
                width: 2rem;
                height: 2rem;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        input:checked + label {
            color: $main-text;

            &:before {
                border-color: $green;
            }

            &:after {
                background-color: $green;
                border-radius: 100%;
                content: '';
                display: block;
                width: .8rem;
                height: .8rem;
                position: absolute;
                top: .6rem;
                left: .6rem;
            }
        }
    }

    &-error {
        font-size: 1.4rem;
        margin: -1rem 0 0;
    }
}

.row-centered {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin-right: -15px;
    margin-left: -15px;
}