.footer {
    background-color: $main-text;
    color: $white;
    font-size: 1.2rem;
    padding: 2.8rem 0 1.5rem;
    position: relative;
    margin-top: auto;

    a {
        color: $white;
    }

    &-content {
        padding-left: 8.1rem;

        @media screen and (max-width: $tablet) {
            padding-left: 1.5rem;
        }

        @media screen and (max-width: $smartphone-small) {
            .footer-menu:first-child {
                margin-bottom: 0;
            }
        }
    }

    &-menu {
        display: flex;
        margin: 0 0 2.2rem 0;

        li {
            margin-right: 6.6rem;

            &:first-child {
                width: 12.6rem;
            }

            @media screen and (max-width: $smartphone) {
                margin-right: 3rem;
            }
        }

        @media screen and (max-width: $smartphone-small) {
            flex-direction: column;

            li {
                margin: 0 0 1rem;
            }
        }

        @media screen and (max-width: $device-540) {
            flex-direction: column;
        }
    }

    .copyrightContainer {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: $smartphone) {
            justify-content: center;
        }
    }

    .copyrightInfo {
        position: relative;
        width: 50%;
        margin-left: 4rem;

        @media screen and (max-width: $smartphone) {
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }

    .copyright {
        color: $white;
        margin: 0;
        padding: 1rem 0 0;
    }

    .social {
        display: flex;
        flex-direction: row;
        gap: 2.5rem;
    }

    @media screen and (max-width: $smartphone) {
        .social {
            margin: 1rem 0;
        }
    }

    .social-link {
        font-size: 2.5rem;

        a:hover {
            color: $dark-green;
        }
    }

    .social-error, .dashboard-error {
        color: $red;
        font-size: 1.7rem;
    }
}