.verification-code-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.verification-code-input {
    text-align: center;
}

.verification-code-timer {
    margin-top: 1rem;
}